<script lang="ts" setup>
import {useAuthStore} from "~/stores/authUser";
const localePath = useLocalePath()

definePageMeta({
  layout: 'auth'
})

const {t} = useI18n({
  useScope: 'global'
})

const loginForm = reactive({
  username: '',
  password: ''
})

const submitHandler = async (formData, node) => {
  try {
    const {login} = useAuthStore()

    await login({
      username: formData.username,
      password: formData.password
    })

    const {query} = useRoute()

    if (query.redirect) {
      navigateTo(localePath(`${query.redirect}`));
      return;
    }
    const {isAdmin, isDealer, isBackOffice} = useAuthStore()

    if (isAdmin || isBackOffice) {
      navigateTo(localePath('/dealer/cars'))
    }

    if (isDealer) {
      navigateTo(localePath('/dealer'))
    }
    return;
  } catch (e) {
    node.setErrors(
      [e.message],
    )
    return false;
  }
}

</script>

<template>
  <div>
    <FormKit
      id="reset"
      v-slot="{ value, disabled }"
      v-model="loginForm"
      type="form"
      :actions="false"
      @submit="submitHandler"
    >
      <h2 class="form-title">
        {{ t('auth.login.title') }}
      </h2>

      <FormKit
        type="text"
        name="username"
        :placeholder="t('common.labels.username')"
        validation="required"
      />

      <FormKit
        type="password"
        name="password"
        :placeholder="t('common.labels.password')"
      />

      <div class="form-nav">
        <div class="link">
          <NuxtLink :to="localePath('/auth/register')">
            {{ t('auth.login.register') }}
          </NuxtLink>
        </div>
        <div class="link">
          <NuxtLink :to="localePath('/auth/reset')">
            {{ t('auth.login.forgotPassword') }}
          </NuxtLink>
        </div>
      </div>

      <FormKit
        type="submit"
        variant="primary"
        width="full"
        :label="t('auth.login.submit')"
      />
    </FormKit>
    
    <NuxtLink :to="localePath('/guest/cars')">
      <FormKit
        type="button"
        variant="outline"
        width="full"
        :label="t('auth.login.asGuest')"
      />
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">

  .form-title {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }

  .form-item {
    width: 100%;
    margin-bottom: 25px;
  }

  .form-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0 30px;

    .link {
      font-size: 12px;
    }
  }

  .btn {
    width: 100%;
  }

</style>

<i18n lang="json">
{
  "en": {
    "signIn": "Sign In"
  },
  "ru": {
    "signIn": "Войти"
  }
}
</i18n>
